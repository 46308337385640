
import { Vue, Component } from "vue-property-decorator";
import {EmpreendimentoService, PessoaService, SituacaoContratoService, TipoAditamentoContrato} from "@/core/services/cadastros";

@Component
export default class CfgContratoAditado extends Vue {

  filter: {
    empreendimentoId: [];
    situacaoContratoId:[];
    dataAditamentoInicial: string;
    dataAditamentoFinal: string;
    numeroContrato:number;
    clienteId:number;
    corretorId:number;
    aditamentoPago:any;
    tipoAditamentoId:[];
  } = {
    empreendimentoId: [],
    situacaoContratoId:[],
    dataAditamentoInicial: '',
    dataAditamentoFinal:'',
    numeroContrato:0,
    clienteId:0, 
    corretorId:0,
    aditamentoPago:"",
    tipoAditamentoId:[]
  };  

    SimNao = [
        {id:1, nome:"Sim", condicao:true},
        {id:2, nome:"Não", condicao:false},
    ]

     breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Contratos Aditados', disabled: true, href: '/financeiro/painel/despesas'}
  ]

    valid = true;
    empreendimentos: any = [];
    pessoas = [];
    tipos: any = [];
    tiposAditamentos:any = [];

    get selecionarTodosEmpreendimentos(){
        return this.filter.empreendimentoId.length === this.empreendimentos.length
    }

    get SelecionarAlgunsEmpreendimentos(){
        return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    get iconEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
        return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpreendimentos) {
            this.filter.empreendimentoId = []
            } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
            }
        })
    }

    SelecionarTipo(){
      this.$nextTick(() => {
        if (this.SelecionarTodos) {
          this.filter.situacaoContratoId = []
        } else {
          this.filter.situacaoContratoId = this.tipos.slice().map((x:any)=>x.id)
        }
      })
    }
    get SelecionarTodos(){
      return this.filter.situacaoContratoId.length === this.tipos.length
    }

    get SelecionarAlgunsTipos(){
      return this.filter.situacaoContratoId.length > 0 && !this.SelecionarTodos
    }

    get iconEmpresa(){
      if (this.SelecionarTodos) return 'mdi-close-box'
      if (this.SelecionarAlgunsTipos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.SelecionarTodos) return 'Desmarcar'
      if (this.SelecionarAlgunsTipos) return 'Selecionando...'
      return 'Selecionar todos'
    } 
  

  SelecionarTipoAditamento(){
      this.$nextTick(() => {
        if (this.SelecionarTodostipoAditamento) {
          this.filter.tipoAditamentoId = []
        } else {
          this.filter.tipoAditamentoId = this.tiposAditamentos.slice().map((x:any)=>x.id)
        }
      })
    }
    get SelecionarTodostipoAditamento(){
      return this.filter.tipoAditamentoId.length === this.tiposAditamentos.length
    }

    get SelecionarAlgunsTipostipoAditamento(){
      return this.filter.tipoAditamentoId.length > 0 && !this.SelecionarTodostipoAditamento
    }

    get icontipoAditamento(){
      if (this.SelecionarTodostipoAditamento) return 'mdi-close-box'
      if (this.SelecionarTodostipoAditamento) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get texttipoAditamento(){
      if (this.SelecionarTodostipoAditamento) return 'Desmarcar'
      if (this.SelecionarTodostipoAditamento) return 'Selecionando...'
      return 'Selecionar todos'
    } 
 
    mounted() { 
      new TipoAditamentoContrato().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res =>{
            this.tiposAditamentos = res.data.items
        }
      )
        new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.pessoas = res.data.items;
        });

        new SituacaoContratoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.tipos = res.data.items;
        });

        new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
            this.empreendimentos = res.data.items;
        }); 
    } 
  
    Visualizar() {
        if(!this.filter.dataAditamentoInicial || !this.filter.dataAditamentoFinal){
            this.$swal("Aviso", "Obrigatório preencher início e fim da data.", "warning");
            return true;
        }      
        let routeLink = this.$router.resolve({ 
        name: "RelContratoAditado",
            query: {
                empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
                situacaoContratoId: this.filter.situacaoContratoId ? this.filter.situacaoContratoId.toString() : "",
                dataAditamentoInicial: this.filter.dataAditamentoInicial ? this.filter.dataAditamentoInicial.toString() : "",
                dataAditamentoFinal: this.filter.dataAditamentoFinal ? this.filter.dataAditamentoFinal.toString() : "",
                numeroContrato: this.filter.numeroContrato ? this.filter.numeroContrato.toString() : "",
                clienteId: this.filter.clienteId ? this.filter.clienteId.toString() : "",
                corretorId: this.filter.corretorId ? this.filter.corretorId.toString() : "",
                aditamentoPago: this.filter.aditamentoPago != null ? this.filter.aditamentoPago.toString() : "",
                tipoAditamentoId: this.filter.tipoAditamentoId ? this.filter.tipoAditamentoId.toString() : "",
            },
        });
        window.open(routeLink.href, "_blank");
    }
}
